<template>
  <main class="main">
    <div class="container">
      <div class="main__inner" v-if="service">
        <BreadcrumbsComponent :title="service.title" v-if="service.title" />
        <section class="section section-pb48">
          <div class="services_faq services_faq-reverse">
            <div class="services_faq-content">
              <div class="services_faq-top">
                <h2>{{ service.title }}</h2>
                <div class="services_faq__description" v-if="hasText(service.description)">
                  <EditorJSComponent
                    :text="JSON.parse(service.description)"
                    v-if="isJson(service.description) && JSON.parse(service.description)"
                  />
                  <span v-else>{{ service.description }}</span>
                </div>
              </div>
              <div class="services_faqs-list">
                <div v-for="(item, index) in infoServices" :key="index" class="faq-item">
                  <a
                    href="#"
                    class="question"
                    :class="{ active: isActive === index }"
                    @click.prevent="setActive(index)"
                  >
                    <div class="title">{{ item.category.title }}</div>
                    <ArrowDownIcon class="arrow" />
                  </a>
                  <transition name="fade">
                    <div v-show="isActive === index" class="answer" v-if="item.description">
                      <EditorJSComponent
                        :text="JSON.parse(item.description)"
                        v-if="isJson(item.description) && JSON.parse(item.description)"
                      />
                      <span v-else>{{ item.description }}</span>
                    </div>
                  </transition>
                </div>
              </div>
              <div v-if="service.join_documents && service.join_documents.length">
                <div class="files">
                  <div class="file" :key="index" v-for="(item, index) in service.join_documents">
                    <div class="file-main">
                      <img src="/public/images/open/document.png" alt="" />
                      <div class="file__info">
                        <a
                          :href="$store.state.api + item.document.url"
                          download
                          target="_blank"
                          v-if="item.document.title"
                          class="file__info-title"
                          >{{ item.document.title }}
                        </a>
                        <div class="file__info-size">{{ item.document.type }} ({{ item.document.size }})</div>
                      </div>
                    </div>
                    <hr v-if="index !== service.join_documents.length - 1" />
                  </div>
                </div>
              </div>
            </div>
            <div class="services__info">
              <div class="services_faq-top services_faq-top-mobile">
                <h2>{{ service.title }}</h2>
                <div class="services_faq__description" v-if="hasText(service.description)">
                  <EditorJSComponent
                    :text="JSON.parse(service.description)"
                    v-if="isJson(service.description) && JSON.parse(service.description)"
                  />
                  <span v-else>{{ service.description }}</span>
                </div>
              </div>
              <div class="item" v-if="service.is_electronic_service">
                <a :href="service.link_electronic_service" target="blank">
                  <button class="button btn-blue">Получить услугу онлайн</button>
                </a>
              </div>
              <div class="item" v-if="service.join_department && service.join_department.department">
                <div class="title">Управление оказывающее услугу</div>
                <router-link class="link" to="#">{{ service.join_department.department.title }}</router-link>
              </div>
              <div
                class="item"
                v-if="
                  service.join_department &&
                  service.join_department.department &&
                  service.join_department.department.department_info
                "
              >
                <div class="title">Консультирование заявителей</div>
                <div class="link" v-if="service.join_department.department.department_info.phone">
                  {{ service.join_department.department.department_info.phone }}
                </div>
                <div class="link" v-if="service.join_department.department.department_info.email">
                  {{ service.join_department.department.department_info.email }}
                </div>
              </div>
              <div class="item" v-if="service.order_services && service.order_services.length">
                <div class="title">Где оформить услугу</div>
                <a
                  class="link"
                  v-for="(item, index) in service.order_services"
                  :key="index"
                  :href="item.link"
                  >{{ item.title }}</a
                >
              </div>
              <div class="item" v-if="service.faqs && service.faqs.length">
                <div class="title">Вопросы и ответы</div>
                <router-link class="link" :to="{ name: 'services_faq', params: { id: service.id } }">
                  Часто задаваемые вопросы по этой услуге
                </router-link>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </main>
</template>
<script>
import ArrowDownIcon from "@/components/svg/ArrowDown.vue";
import EditorJSComponent from "components/EditorJSComponent.vue";
import BreadcrumbsComponent from "components/BreadcrumbsComponent.vue";
export default {
  name: "ServicesFAQ",

  async asyncData({ store, route }) {
    await store.dispatch("GET_SERVICE", route.params.id);
  },

  components: {
    EditorJSComponent,
    ArrowDownIcon,
    BreadcrumbsComponent,
  },

  computed: {
    service() {
      return this.$store.state.service;
    },

    infoServices() {
      return this.$store.state.service?.info_services;
    },
  },

  beforeRouteLeave(to, from, next) {
    this.$store.state.service = {};
    next();
  },

  data() {
    return {
      isActive: null,
    };
  },

  methods: {
    hasText(text) {
      if (this.isJson(text)) {
        let parsedText = JSON.parse(text);
        return !!(parsedText && parsedText.blocks && parsedText.blocks.length);
      }
      return !!text;
    },
    setActive(index) {
      if (this.isActive === index) {
        this.isActive = null;
      } else {
        this.isActive = index;
      }
    },
    isJson(str) {
      try {
        JSON.parse(str);
      } catch (e) {
        return false;
      }
      return true;
    },
  },

  metaInfo() {
    return this.$seo(
      "common",
      "Официальный сайт администрации города Махачкалы",
      "",
      "",
      "Официальный сайт администрации города Махачкалы",
      "",
      ""
    );
  },
};
</script>

<style lang="stylus">
@import "~@/styles/parts/services/index.styl"
</style>
